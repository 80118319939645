import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/spindle.ameba.design/spindle.ameba.design/src/components/Layout.tsx";
import SEO from '../../../components/SEO';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageTitle = makeShortcode("PageTitle");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="UIラベリング - コンテンツ" mdxType="SEO" />
    <PageTitle title="UIラベリング" enTitle="UI Labeling" mdxType="PageTitle" />
    <p>{`UI内部で使用するラベリングの基本的なルールを定義しています。`}</p>
    <h2 {...{
      "id": "するの活用",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%81%99%E3%82%8B%E3%81%AE%E6%B4%BB%E7%94%A8",
        "aria-label": "するの活用 permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`「する」の活用`}</h2>
    <h3 {...{
      "id": "ルール",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%AB%E3%83%BC%E3%83%AB",
        "aria-label": "ルール permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`ルール`}</h3>
    <ul>
      <li parentName="ul">{`漢語の名詞を語幹とした動作性名詞は`}<strong parentName="li">{`活用する`}</strong></li>
      <li parentName="ul">{`外来語を語幹とした動詞は`}<strong parentName="li">{`活用しない`}</strong></li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/fb9806720a53299dab1cc357120138b4/b17f8/ui-labeling-rule-1.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "38.125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAIABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAEF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB26AH/8QAFBABAAAAAAAAAAAAAAAAAAAAEP/aAAgBAQABBQJ//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFBABAAAAAAAAAAAAAAAAAAAAEP/aAAgBAQAGPwJ//8QAGxAAAgEFAAAAAAAAAAAAAAAAAREAECExUXH/2gAIAQEAAT8hZeLdgOwqf//aAAwDAQACAAMAAAAQgA//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAaEAEAAwADAAAAAAAAAAAAAAABABEhUbHx/9oACAEBAAE/ENZXg+Iz1W4us//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/fb9806720a53299dab1cc357120138b4/2aaaf/ui-labeling-rule-1.webp 160w", "/static/fb9806720a53299dab1cc357120138b4/85e47/ui-labeling-rule-1.webp 320w", "/static/fb9806720a53299dab1cc357120138b4/75198/ui-labeling-rule-1.webp 640w", "/static/fb9806720a53299dab1cc357120138b4/691bc/ui-labeling-rule-1.webp 960w", "/static/fb9806720a53299dab1cc357120138b4/223e5/ui-labeling-rule-1.webp 1280w", "/static/fb9806720a53299dab1cc357120138b4/2158a/ui-labeling-rule-1.webp 1600w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/fb9806720a53299dab1cc357120138b4/0913d/ui-labeling-rule-1.jpg 160w", "/static/fb9806720a53299dab1cc357120138b4/cb69c/ui-labeling-rule-1.jpg 320w", "/static/fb9806720a53299dab1cc357120138b4/c08c5/ui-labeling-rule-1.jpg 640w", "/static/fb9806720a53299dab1cc357120138b4/6a068/ui-labeling-rule-1.jpg 960w", "/static/fb9806720a53299dab1cc357120138b4/eea4a/ui-labeling-rule-1.jpg 1280w", "/static/fb9806720a53299dab1cc357120138b4/b17f8/ui-labeling-rule-1.jpg 1600w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/fb9806720a53299dab1cc357120138b4/c08c5/ui-labeling-rule-1.jpg",
              "alt": "「する」の活用のルールの例。",
              "title": "「する」の活用のルールの例。",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`そのラベルを利用してる画面で、動詞が3つ以上反復していて、なおかつそれが動作を示唆するものであることが明らかであれば`}</strong></p>
    <ul>
      <li parentName="ul">{`動詞／動作性名詞は一律`}<strong parentName="li">{`活用しない`}</strong></li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/55a8388e3d58b6954c790ff965dc8fc8/b17f8/ui-labeling-rule-2.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "38.125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAIABQDASIAAhEBAxEB/8QAFwABAAMAAAAAAAAAAAAAAAAAAAECBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAdi4SD//xAAYEAADAQEAAAAAAAAAAAAAAAAAAQISIf/aAAgBAQABBQKp0LiP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGRAAAQUAAAAAAAAAAAAAAAAAAQAQETJx/9oACAEBAAY/ArEYob//xAAbEAACAQUAAAAAAAAAAAAAAAABEQAQITFBUf/aAAgBAQABPyG3w+lASxK2af/aAAwDAQACAAMAAAAQ8A//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAaEAEAAgMBAAAAAAAAAAAAAAABACERMWFx/9oACAEBAAE/EK/YXc9h1BAJlesWf//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/55a8388e3d58b6954c790ff965dc8fc8/2aaaf/ui-labeling-rule-2.webp 160w", "/static/55a8388e3d58b6954c790ff965dc8fc8/85e47/ui-labeling-rule-2.webp 320w", "/static/55a8388e3d58b6954c790ff965dc8fc8/75198/ui-labeling-rule-2.webp 640w", "/static/55a8388e3d58b6954c790ff965dc8fc8/691bc/ui-labeling-rule-2.webp 960w", "/static/55a8388e3d58b6954c790ff965dc8fc8/223e5/ui-labeling-rule-2.webp 1280w", "/static/55a8388e3d58b6954c790ff965dc8fc8/2158a/ui-labeling-rule-2.webp 1600w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/55a8388e3d58b6954c790ff965dc8fc8/0913d/ui-labeling-rule-2.jpg 160w", "/static/55a8388e3d58b6954c790ff965dc8fc8/cb69c/ui-labeling-rule-2.jpg 320w", "/static/55a8388e3d58b6954c790ff965dc8fc8/c08c5/ui-labeling-rule-2.jpg 640w", "/static/55a8388e3d58b6954c790ff965dc8fc8/6a068/ui-labeling-rule-2.jpg 960w", "/static/55a8388e3d58b6954c790ff965dc8fc8/eea4a/ui-labeling-rule-2.jpg 1280w", "/static/55a8388e3d58b6954c790ff965dc8fc8/b17f8/ui-labeling-rule-2.jpg 1600w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/55a8388e3d58b6954c790ff965dc8fc8/c08c5/ui-labeling-rule-2.jpg",
              "alt": "動詞／動作性名詞は一律活用しない例。",
              "title": "動詞／動作性名詞は一律活用しない例。",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`端末幅などの問題で、レイアウト上に最低限の文字量であることの制約があるなら`}</strong></p>
    <ul>
      <li parentName="ul">{`文脈に関わらず動詞／動作性名詞は一律`}<strong parentName="li">{`活用しない`}</strong></li>
    </ul>
    <h3 {...{
      "id": "サ変動詞について",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%82%B5%E5%A4%89%E5%8B%95%E8%A9%9E%E3%81%AB%E3%81%A4%E3%81%84%E3%81%A6",
        "aria-label": "サ変動詞について permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`サ変動詞について`}</h3>
    <p>{`漢語に「する」などの活用を以て動詞とするもの。`}</p>
    <p><a parentName="p" {...{
        "href": "https://ja.wikipedia.org/wiki/%E3%82%B5%E8%A1%8C%E5%A4%89%E6%A0%BC%E6%B4%BB%E7%94%A8"
      }}>{`サ行変格活用`}</a></p>
    <p>{`種類としては下記などがあります。`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://ja.wikipedia.org/wiki/%E6%BC%A2%E8%AA%9E"
        }}>{`漢語`}</a>{`の`}<a parentName="li" {...{
          "href": "https://ja.wikipedia.org/wiki/%E5%90%8D%E8%A9%9E"
        }}>{`名詞`}</a>{`に「する」が付いたパターン`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://ja.wikipedia.org/wiki/%E5%A4%96%E6%9D%A5%E8%AA%9E"
        }}>{`外来語`}</a>{`を`}<a parentName="li" {...{
          "href": "https://ja.wikipedia.org/wiki/%E8%AA%9E%E5%B9%B9"
        }}>{`語幹`}</a>{`とするもの（例：「キャッチする」)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://ja.wikipedia.org/wiki/%E5%92%8C%E8%AA%9E"
        }}>{`和語`}</a>{`の名詞＋「する」の形のもの（例：「早起きする」）`}</li>
      <li parentName="ul">{`擬態語＋「する」の形のもの（例：「どきどきする」）`}</li>
    </ul>
    <h3 {...{
      "id": "活用するメリット",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E6%B4%BB%E7%94%A8%E3%81%99%E3%82%8B%E3%83%A1%E3%83%AA%E3%83%83%E3%83%88",
        "aria-label": "活用するメリット permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`活用するメリット`}</h3>
    <ul>
      <li parentName="ul">{`情緒性がある`}</li>
      <li parentName="ul">{`動作を示唆する事が一目で分かるということ`}</li>
    </ul>
    <h3 {...{
      "id": "活用するデメリット",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E6%B4%BB%E7%94%A8%E3%81%99%E3%82%8B%E3%83%87%E3%83%A1%E3%83%AA%E3%83%83%E3%83%88",
        "aria-label": "活用するデメリット permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`活用するデメリット`}</h3>
    <ul>
      <li parentName="ul">{`冗長さ`}</li>
      <li parentName="ul">{`リストなどで「する」が複数反復することによるゲシュタルト崩壊`}</li>
    </ul>
    <h2 {...{
      "id": "機能名動詞の活用",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E6%A9%9F%E8%83%BD%E5%90%8D%E5%8B%95%E8%A9%9E%E3%81%AE%E6%B4%BB%E7%94%A8",
        "aria-label": "機能名動詞の活用 permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`機能名動詞の活用`}</h2>
    <ul>
      <li parentName="ul">{`フォロー`}</li>
      <li parentName="ul">{`いいね`}</li>
      <li parentName="ul">{`コメント`}</li>
      <li parentName="ul">{`ログイン`}</li>
      <li parentName="ul">{`Pick`}</li>
      <li parentName="ul">{`応援`}</li>
    </ul>
    <p>{`上記のような、それぞれ機能名がそのまま動詞になりうるケースを機能名動詞と呼称します。`}</p>
    <h3 {...{
      "id": "ルール-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%AB%E3%83%BC%E3%83%AB-1",
        "aria-label": "ルール 1 permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`ルール`}</h3>
    <h4>{`世間的認知を獲得している機能名動詞`}</h4>
    <p>{`活用しなくても問題ありません。下記などがあります。`}</p>
    <ul>
      <li parentName="ul">{`フォロー`}</li>
      <li parentName="ul">{`いいね`}</li>
      <li parentName="ul">{`コメント`}</li>
      <li parentName="ul">{`ログイン`}</li>
      <li parentName="ul">{`キャンセル`}</li>
      <li parentName="ul">{`リトライ`}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/b5cd922b45530fafef6a814304b92318/b17f8/ui-labeling-follow.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "38.125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAIABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAEF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB3KEB/8QAFBABAAAAAAAAAAAAAAAAAAAAEP/aAAgBAQABBQJ//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFBABAAAAAAAAAAAAAAAAAAAAEP/aAAgBAQAGPwJ//8QAFBABAAAAAAAAAAAAAAAAAAAAEP/aAAgBAQABPyF//9oADAMBAAIAAwAAABCAD//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABYQAQEBAAAAAAAAAAAAAAAAAAEAQf/aAAgBAQABPxCUyW//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/b5cd922b45530fafef6a814304b92318/2aaaf/ui-labeling-follow.webp 160w", "/static/b5cd922b45530fafef6a814304b92318/85e47/ui-labeling-follow.webp 320w", "/static/b5cd922b45530fafef6a814304b92318/75198/ui-labeling-follow.webp 640w", "/static/b5cd922b45530fafef6a814304b92318/691bc/ui-labeling-follow.webp 960w", "/static/b5cd922b45530fafef6a814304b92318/223e5/ui-labeling-follow.webp 1280w", "/static/b5cd922b45530fafef6a814304b92318/2158a/ui-labeling-follow.webp 1600w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/b5cd922b45530fafef6a814304b92318/0913d/ui-labeling-follow.jpg 160w", "/static/b5cd922b45530fafef6a814304b92318/cb69c/ui-labeling-follow.jpg 320w", "/static/b5cd922b45530fafef6a814304b92318/c08c5/ui-labeling-follow.jpg 640w", "/static/b5cd922b45530fafef6a814304b92318/6a068/ui-labeling-follow.jpg 960w", "/static/b5cd922b45530fafef6a814304b92318/eea4a/ui-labeling-follow.jpg 1280w", "/static/b5cd922b45530fafef6a814304b92318/b17f8/ui-labeling-follow.jpg 1600w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/b5cd922b45530fafef6a814304b92318/c08c5/ui-labeling-follow.jpg",
              "alt": "フォローボタンのラベリングの例。",
              "title": "フォローボタンのラベリングの例。",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h4>{`Ameba固有の機能名であったり、世間的に機能名動詞として認知されていない言葉`}</h4>
    <p>{`下記などがあり、できるだけ活用してください。`}</p>
    <ul>
      <li parentName="ul">{`Pickする`}</li>
      <li parentName="ul">{`応援する`}</li>
      <li parentName="ul">{`デザインする`}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/371de387705f7b0759a3e29cf21af0ee/b17f8/ui-labeling-pick.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "38.125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAIABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAEF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB26AH/8QAFhAAAwAAAAAAAAAAAAAAAAAAARAx/9oACAEBAAEFAjF//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFBABAAAAAAAAAAAAAAAAAAAAEP/aAAgBAQAGPwJ//8QAGRAAAgMBAAAAAAAAAAAAAAAAAXEAECFB/9oACAEBAAE/IcEpwKv/2gAMAwEAAgADAAAAEPAP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAGRABAAMBAQAAAAAAAAAAAAAAAQARITFR/9oACAEBAAE/EFb3E52Db+h2LP/Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/371de387705f7b0759a3e29cf21af0ee/2aaaf/ui-labeling-pick.webp 160w", "/static/371de387705f7b0759a3e29cf21af0ee/85e47/ui-labeling-pick.webp 320w", "/static/371de387705f7b0759a3e29cf21af0ee/75198/ui-labeling-pick.webp 640w", "/static/371de387705f7b0759a3e29cf21af0ee/691bc/ui-labeling-pick.webp 960w", "/static/371de387705f7b0759a3e29cf21af0ee/223e5/ui-labeling-pick.webp 1280w", "/static/371de387705f7b0759a3e29cf21af0ee/2158a/ui-labeling-pick.webp 1600w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/371de387705f7b0759a3e29cf21af0ee/0913d/ui-labeling-pick.jpg 160w", "/static/371de387705f7b0759a3e29cf21af0ee/cb69c/ui-labeling-pick.jpg 320w", "/static/371de387705f7b0759a3e29cf21af0ee/c08c5/ui-labeling-pick.jpg 640w", "/static/371de387705f7b0759a3e29cf21af0ee/6a068/ui-labeling-pick.jpg 960w", "/static/371de387705f7b0759a3e29cf21af0ee/eea4a/ui-labeling-pick.jpg 1280w", "/static/371de387705f7b0759a3e29cf21af0ee/b17f8/ui-labeling-pick.jpg 1600w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/371de387705f7b0759a3e29cf21af0ee/c08c5/ui-labeling-pick.jpg",
              "alt": "Pickボタンのラベリングの例。",
              "title": "Pickボタンのラベリングの例。",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>
※基本的に認知レベルの低い機能名動詞は追加しないほうがユーザーの混乱を防ぐことに繋がります。<br />
※新しく機能名動詞を定義する場合は、動詞としての活用も定義しておきましょう。<br />
※機能名動詞を活用させないと、ボイスオーバー時に読み上げられた単語がアクションを誘発するものなのかただの機能名を述べているのかが判別できなくなる可能性が高くなります。
    </p>
    <h2 {...{
      "id": "読点と句点",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E8%AA%AD%E7%82%B9%E3%81%A8%E5%8F%A5%E7%82%B9",
        "aria-label": "読点と句点 permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`読点と句点`}</h2>
    <h3 {...{
      "id": "ルール-2",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%AB%E3%83%BC%E3%83%AB-2",
        "aria-label": "ルール 2 permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`ルール`}</h3>
    <h4>{`見出しの場合`}</h4>
    <p>{`読点、句点は必要ありません。`}</p>
    <h4>{`1〜2行程度の一つの文の場合`}</h4>
    <p>{`読点、句点は基本的に必要ありませんが、文中に読点が入る場合は句点を入れましょう。`}</p>
    <h4>{`複数の文章が入る、body文の場合`}</h4>
    <p>{`読点、句点は必要となります。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      